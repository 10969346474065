import axios from "axios";
import store from "@/store"
import {netuxConfig} from "../../configs.json"
import * as types from "@/store/types"

export async function netuxTurn(sede, triage_level, interviewUser, ubicacion){
    console.log(sede)
    const netux = netuxConfig[process.env.NODE_ENV]
    const API_URL = store.getters[types.API_URL]
    const serviceId= netux.sedes[sede].servicios[triage_level]

    if(!serviceId) throw new Error('No se encuentra servicio de Netux')

    try {
        const netuxResponse = await axios.post(API_URL + 'turnoNetux',
            {
                env: process.env.NODE_ENV,
                netuxData:{
                    "ticketDispensingType": "WEB",
                    "isDeferred": ubicacion==='remoto',
                    "deferredDate": new Date().toISOString(),
                    "serviceId": serviceId,
                    "data": {
                        "firstName": interviewUser.primerNombre,
                        "secondName": interviewUser.segundoNombre,
                        "lastName": interviewUser.primerApellido,
                        "secondLastName": interviewUser.segundoApellido,
                        "document": interviewUser.identificacion,
                        "documentType": "CC"
                    },
                    "confirmed": ubicacion==='sala'
                }
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": netux.token,
                    "Access-Control-Allow-Origin": "*"
                }
            }
        )
        return netuxResponse.data.data.turn

    }catch(e){
        throw Error('Error en la plataforma de turno netux')
    }
}