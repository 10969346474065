import template from "@/views/layouts/template";
import multiguard from "vue-router-multiguard";
import store from "@/store";
import {DISCLAIMER} from "@/store/types";
import firebase from "firebase/app";


const CustomRoutes = [

];

export default CustomRoutes;
