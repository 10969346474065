<template>
	<v-container class="d-flex align-center fill-height">
		<v-card v-if="!!resultados.triage_level">
			<v-card-title>
				<div class="text-h4 primary--text">Resultado</div>
			</v-card-title>
			<v-card-text>
				<v-alert :type="mensajes[ubicacion]?.[resultados.triage_level]?.level" outlined border="left">
					<template v-slot:default>
						<div v-html="mensajes[ubicacion]?.[resultados.triage_level]?.text||'N/D'"/>

						<div v-if="turno" class="text-h4 secondary--text text-center my-5">Tu turno es el: {{turno}}</div>
					</template>

					<template v-slot:prepend v-if="mensajes[ubicacion]?.[resultados.triage_level]">
						<v-icon size="50" class="mr-10" :color="mensajes[ubicacion][resultados.triage_level].color">fad fa-{{mensajes[ubicacion][resultados.triage_level].icon}}</v-icon>
					</template>
				</v-alert>

				<v-alert type="info" v-if="ubicacion!=='remoto'">Esta ventana se cerrara en {{timer}} segundos</v-alert>

				<div class="d-flex justify-center">
					<a href="https://www.avidanti.com/index.php/guia-salateletriage/">VER INSTRUCCIONES</a>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-btn color="primary" @click="reiniciar" dark outlined>nueva consulta</v-btn>
				<v-btn v-if="DEV" color="primary" @click="callTurn" dark outlined>turno</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import * as types from "@/store/types";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {
	API_URL, DEV,
	FIREBASE_ID,
	INTERVIEW_USER,
	MUTATION_DISCLAIMER, MUTATION_FIREBASE_ID,
	MUTATION_RESULTADOS,
	RESET_INTERVIEW,
	RESULTADOS, SEDE, SEDES, UBICACION
} from "@/store/types";
import {mensajesSalida} from "../../../../messages";
import QRious from 'qrious'
import axios from "axios";
import {netuxTurn} from "@/actions/custom";


export default {
	name: "ResultadosPaciente",
	data:()=>({
		telefono: "",
		mensajes:mensajesSalida,
		diagnosticosDisplay: false,

		getCanalEspecialista:{
			audio_teleconsultation: "Llamada telefónica",
			video_teleconsultation: "Video llamada",
			text_teleconsultation: "Chat",
			personal_visit: "Presencial",
		},
		timer: 15,
		turno: null
	}),
	created: async function() {
		if(process.env.NODE_ENV === 'development') {
			const docRef = await db.collection('interviews').doc('vpzVoHkREVsFSUGnTDEL')
			this.$store.commit(MUTATION_FIREBASE_ID, 'vpzVoHkREVsFSUGnTDEL')
			const doc = await docRef.get();
			if(doc.exists) this.$store.commit(MUTATION_RESULTADOS, doc.data())
		}



        new QRious({
            element: document.getElementById("qrcode"),
            background: '#ffffff',
            backgroundAlpha: 1,
            foreground: '#5868bf',
            foregroundAlpha: 1,
            level: 'H',
            padding: 0,
            size: 128,
            value: this.firebaseId||"No code"
        });

	},
	mounted() {
        setTimeout(()=>{this.callTurn()}, 500)
	},
	computed: {
		...mapGetters({
			resultados: RESULTADOS,
			ubicacion: UBICACION,
			interviewUser:INTERVIEW_USER,
			firebaseId: FIREBASE_ID,
			sede: SEDE,
			SEDES,
			API_URL, DEV
		}),
		codigo: function(){return this.firebaseId||'sin código'},

		sedeInfo:function(){
			return _.find(this.SEDES, ['id',parseInt(this.sede)])
		}
	},
	watch:{
		timer: function (val) { if(val === 0 )this.reiniciar(true) },
		sedeInfo: function (val){
			setTimeout(()=>{
				const msg = document.getElementById('ubicacion')
				if(msg) msg.innerHTML = `${val.nombre}, ubicada en ${val.direccion??val.ciudad}`
			}, 500)
		}
	},
	methods:{
		...mapMutations({ setDisclaimer: MUTATION_DISCLAIMER }),
		...mapActions({ resetInterview: RESET_INTERVIEW }),

		temporizadorCierre(){
			if(process.env.NODE_ENV === 'production' && this.ubicacion !== 'remoto')
				setInterval(() => { this.timer --; }, 1000)
		},

		reiniciar(){
			this.resetInterview(true);
			this.setDisclaimer(false)
			this.$router.push({name:'DisclaimerPaciente'});
		},
		async callTurn(){
            if(this.ubicacion==='sala'){
	            try {
					this.turno = await netuxTurn(this.sede, this.resultados.triage_level, this.interviewUser, this.ubicacion)
	            }
				catch (e){ console.log(e) }


	            this.temporizadorCierre()
            }
			else{
	            this.temporizadorCierre()
            }
		}
	}
}
</script>

<style scoped>

</style>
