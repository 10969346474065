import { render, staticRenderFns } from "./preguntasMultipleSingle.vue?vue&type=template&id=102881d9&scoped=true&"
import script from "./preguntasMultipleSingle.vue?vue&type=script&lang=js&"
export * from "./preguntasMultipleSingle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "102881d9",
  null
  
)

export default component.exports