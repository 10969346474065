import { render, staticRenderFns } from "./sugerencias.vue?vue&type=template&id=7565f502&scoped=true&xmlns%3Aslot=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./sugerencias.vue?vue&type=script&lang=js&"
export * from "./sugerencias.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7565f502",
  null
  
)

export default component.exports